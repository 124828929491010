<template>
<div class="product-descriptions">
    <div class="form-group row">
      <label class="col-12 col-form-label" for="input-title">Meta title</label>
      <div class="col-12">
        <input type="text" v-model="data.meta_title" id="input-title" name="title" 
        class="form-control" v-validate="'max:200'" :class="{ 'is-invalid': errors.has('title') }"
         placeholder="Meta title" />
        <span class="text-danger invalid-feedback"  v-show="errors.has('title')">{{ errors.first('title') }}</span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-12 col-form-label" for="input-meta-keys">Meta keys</label>
      <div class="col-12">
        <input type="text" v-model="data.meta_keys" id="input-meta-keys" name="meta-keys"
        class="form-control" v-validate="'max:200'" :class="{ 'is-invalid': errors.has('meta-keys') }" 
        placeholder="Meta keys" />
        <span class="text-danger invalid-feedback"  v-show="errors.has('meta-keys')">{{ errors.first('meta-keys') }}</span>
      </div>
    </div>
    <div class="form-group row mt-3">
      <label class="col-12 col-form-label" for="text-area-meta-desc">Meta Description</label>
      <div class="col-12">
        <textarea id="text-area-meta-desc" v-validate="'max:500'" v-model="data.meta_desc" 
        class="form-control" rows="3" placeholder="Meta description" name="meta-desc"></textarea>
         <span class="text-danger invalid-feedback"  v-show="errors.has('meta-desc')">{{ errors.first('meta-desc') }}</span>
      </div>
    </div>
    <div class="form-group row mt-3">
      <label class="col-12 col-form-label" for="text-area-short-desc">Short Description</label>
      <div class="col-12">
        <textarea id="text-area-short-desc" v-validate="'max:500'" v-model="data.short_description" 
        class="form-control" rows="3" placeholder="Write short description" name="short_desc"></textarea>
         <span class="text-danger invalid-feedback"  v-show="errors.has('short_desc')">{{ errors.first('short_desc') }}</span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <label class="">Main Description</label>
        <quill-editor v-model="data.description" style="min-height: 100px;" :options="editorOption" />
      </div>
    </div>
    <div class="d-flex mt-3 justify-content-end">
      <b-button variant="primary" @click.prevent="updateProduct()" type="button">Update</b-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {
        meta_title: "",
        meta_keys: "",
        meta_desc: "",
        short_description:"",
        description: "",
      },
      editorOption: {
          modules: {
            toolbar: [
              ['bold', 'italic', 'underline', 'strike'],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              [{ 'script': 'sub' }, { 'script': 'super' }],
              [{ 'indent': '-1' }, { 'indent': '+1' }],
              // [{ 'direction': 'rtl' }],
              [{ 'size': ['small', false, 'large', 'huge'] }],
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
              // [{ 'font': [] }],
              // [{ 'color': [] }, { 'background': [] }],
              [{ 'align': [] }],
              ['clean'],
              // ['link', 'image']
            ],
          }
      },
    }
  },
  computed: {
    product(){
        return this.$store.state.productList.product
    },
  },
  methods: {
    updateProduct() {
      this.$validator.validateAll().then(result => {
        if (!result) { this.alertError("Form not valid"); return}
        const formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post(`/products/${this.product.id}/update-metas`,formData)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.data.success){
            this.$store.commit("productList/UPDATE_DESCRIPTION", response.data.data)
          }
        })
      })
    },
    patchProduct() {
      if(this.product.description){
        this.data = {
          meta_title: this.product.description.meta_title,
          meta_keys: this.product.description.meta_keys,
          meta_desc: this.product.description.meta_desc,
          short_description: this.product.description.short_description,
          description: this.product.description.description,
        }
      }
    },
  },
  created(){
    this.patchProduct()
  },
}
</script>
