<template>
  <div class="product-overview">
    <div class="row">
        <div class="col-lg-4 col-md-6 order-lg-2 mb-3 mb-lg-0">
            <product-image />
        </div>
        <div class="col-lg-8">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group mb-3">
                    <label for="product-name">
                        Product Name
                        <span class="text-danger">*</span>
                    </label>
                    <input type="text" v-validate="{ required: true}" v-model="data.name" id="product-name" name="name" 
                    class="form-control" :class="{ 'is-invalid': errors.has('name') }" placeholder="Name" />
                    <span class="text-danger invalid-feedback"  v-show="errors.has('name')">{{ errors.first('name') }}</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group mb-3">
                        <label for="slug">
                            Slug
                            <span class="text-danger">*</span>
                        </label>
                        <input type="text" v-model="data.slug" v-validate="{ required: true, regex: /^[a-z0-9-_]+$/}" 
                        id="slug" class="form-control" name="slug" :class="{ 'is-invalid': errors.has('slug')}" placeholder="Slug" />
                        <span class="text-danger invalid-feedback" v-show="errors.has('slug')">{{ errors.first('slug') }}</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group mb-3">
                    <label for="product-price">
                        Price (dollar)
                        <span class="text-danger">*</span>
                    </label>
                    <input type="number" v-validate="{ required: true, regex: /^[0-9]+$/}" v-model="data.price" class="form-control"
                     name="price" id="product-price" placeholder="Enter Price" />
                    <span class="text-danger invalid-feedback" v-show="errors.has('price')">{{ errors.first('price') }}</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group mb-3">
                    <label class="">Category</label>
                    <select class="custom-select" v-validate="{required: true}" v-model="data.category_id" name="category" placeholder="--select category--">
                        <option disabled value="" key="-1">-- choose category--</option>
                        <option :value="category.id" v-for="(category, index) in categoryList" :key="index">{{category.name}}</option>
                    </select>
                    <span class="text-danger invalid-feedback"  v-show="errors.has('category')">{{ errors.first('category') }}</span>
                    </div>
                </div>
                <div class="col-md-12 d-sm-flex">
                    <div class="form-group mb-2 mr-3 mb-md-0 align-self-center">
                        <b-form-checkbox v-model="data.is_featured" name="is-featured" switch>
                            Featured Product
                        </b-form-checkbox>
                    </div>
                    <div class="form-group mb-2 mr-3 mb-md-0 align-self-center">
                        <b-form-checkbox v-model="data.is_active" name="is-active" switch>
                            Active
                        </b-form-checkbox>
                    </div>
                    <div class="form-group mb-2 mr-3 mb-md-0 align-self-center">
                        <b-form-checkbox v-model="data.is_popular" name="is-popular" switch>
                            Popular Product
                        </b-form-checkbox>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group mt-3">
                    <b-button variant="primary" @click.prevent="updateProduct" type="button">Save changes</b-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div>
      <product-banner />
    </div>
  </div>
</template>
<script>
import ProductImage from './ProductImage.vue';
import ProductBanner from './ProductBanner.vue';

export default {
  components:{
    ProductImage,
    ProductBanner
  },
  data() {
    return {
      data: {}
    }
  },
  computed: {
    product(){
      return this.$store.state.productList.product
    },
    categoryList() {
      return this.$store.state.categoryList.categories.map((cat) => {
        return {id:cat.id, name:cat.name} });
    },
  },
  methods: {
    updateProduct() {
      this.$validator.validateAll().then(result => {
        if (!result) { this.alertError("Form not valid"); return}
        const formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post(`/products/${this.product.id}/update`, formData)
        .then((response)=>{
          this.$store.dispatch("changeLoaderValue")
          if(response.data.success){
            this.$store.commit("productList/UPDATE_PRODUCT", response.data.data)
          }
        })
      })
    },
    patchProduct() {
      this.data = {
        name: this.product.name,
        category_id: this.product.category_id,
        slug: this.product.slug,
        is_featured: this.product.is_featured,
        is_popular: this.product.is_popular,
        is_active: this.product.is_active,
        price: this.product.price,
        price_slash: this.product.price_slash,
      }
    },
  },
  created(){
    this.patchProduct()
  },
}
</script>
