<template>
<div>
    <vue-dropzone id="uploadProductVideoDrop" ref="uploadProductVideoDrop"
       :use-custom-slot="true" :maxFiles="1"
       acceptedFiles="video/*,.mp4,.bmp,.m4v,.avi,.mpg,.mp4">
        <div class="needsclick m-0" >
          <i class="h1 text-muted ri-upload-cloud-2-line"></i>
          <h4>Drop files here or click to upload.</h4>
          <span class="text-muted font-13">
            (Should be only a Video)
          </span>
        </div>
    </vue-dropzone>
    <div class="d-flex mt-3 justify-content-end">
        <b-button variant="primary" @click.prevent="uploadVideo()" type="button">Upload</b-button>
    </div>
</div>
</template>

<script>
import VueDropzone from '@/components/VueDropzone.vue';
export default {
  components: { VueDropzone },
  methods: {
    uploadVideo(){
      if(!this.collectFiles().length){
          this.alertError("A video file is required"); return;
      }
      let formData = new FormData();
      formData.append('video', this.collectFiles()[0])
      this.$store.dispatch("changeLoaderValue", true)
      this.$http.post(`/portfolios/${this.$route.params.productId}/add-video`,formData)
      .then((response) => {
        this.$store.dispatch("changeLoaderValue", false)
        if(response.data.success){
          this.$store.commit("productList/SET_PORTFOLIOS", response.data.data)
          this.clearAll()
        }
      })
    },
    collectFiles(){
      var arrafile = [];
      if(this.$refs.uploadProductVideoDrop){
        arrafile = [...this.$refs.uploadProductVideoDrop.getAcceptedFiles()];
      }
      return arrafile
    },
    clearAll(){
      if(this.$refs.uploadProductVideoDrop){this.$refs.uploadProductVideoDrop.removeAllFiles();}
      this.$emit('clearAll');
    },
  },
}
</script>
