<template>
  <div>
    <div class="row">
      <div class="col-12">
        <ul class="list-group">
          <li class="list-group-item" v-for="(portfolio, index) in paginatedPortfolios" :key="index" >
            <div class="row">
              <div class="col-sm-4 mb-2 mb-md-0 position-relative" @click="() => showImg(portfolio.id)" >
                <span v-if="portfolio.is_video" class="p-1 video-icon-dp rounded-circle position-absolute">
                    <i class="ri-play-circle-line"></i>
                </span>
                <b-img thumbnail class="mr-3" fluid :src="absoluteUrl(portfolio.thumb_path)" alt="portfolios-img"></b-img>
              </div>
              <div class="col-12 col-md-8">
                <span class="d-flex justify-content-between">
                  <span class="text-secondary font-sm image-name mr-2">{{portfolio.image | truncate_start(24)}}</span>
                  <span>
                    <b-dropdown variant="light" right size="sm">
                      <template slot="button-content">
                          Action
                          <i class="mdi mdi-chevron-down"></i>
                      </template>
                      <b-dropdown-item-button @click="initEditPort(portfolio)"><i class="mdi mdi-square-edit-outline"></i> Edit</b-dropdown-item-button>
                      <b-dropdown-item-button @click="deleteItem(portfolio.id)"><i class="mdi mdi-trash-can-outline"></i> Delete</b-dropdown-item-button>
                    </b-dropdown>
                  </span>
                </span>
              </div>
            </div>
          </li>
        </ul>
        <div class="row">
          <div class="col-12">
            <CoolLightBox
              :items="coolLightBoxFiles"
              :index="coolLightBoxIndex"
              :useZoomBar="true"
              @close="coolLightBoxIndex = null">
            </CoolLightBox>
          </div>
          <div class="col-12 d-flex justify-content-end">
            <div class="text-md-right mt-2 pagination-rounded">
              <b-pagination v-model="pagination.currentPage"
              :total-rows="portfolios.length"
              :per-page="pagination.perPage" @input="onPageChange"></b-pagination>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <b-button variant="info" class="mt-2 mr-3" size="sm" @click.prevent="uploadVideoModalShow = true">Add Video</b-button>
          <b-button variant="primary" class="mt-2" size="sm" @click.prevent="uploadModalShow = true">Add Portfolios</b-button>
        </div>
      </div>
    </div>

    <b-modal v-model="uploadVideoModalShow" hide-footer centered title="Upload Video" title-class="font-18">
        <UploadVideo @clearAll="clearAll()" />
    </b-modal>

    <b-modal v-model="uploadModalShow" hide-footer centered 
      title="Add Product Portfolios" title-class="font-18">
      <vue-dropzone id="productUploadImageDrop" ref="productUploadImageDrop"
      :use-custom-slot="true" :maxFiles="10">
        <div class="needsclick m-0">
          <i class="h1 text-muted ri-upload-cloud-2-line"></i>
          <h4>Drop files here or click to upload.</h4>
          <span class="text-muted font-13">
           (Each File should not be more than 2MB)
          </span>
        </div>
      </vue-dropzone>
      <div class="d-flex mt-3 justify-content-end">
        <b-button class="mr-1"  @click.prevent="uploadModalShow = false">Close</b-button>
        <b-button variant="primary" @click.prevent="uploadPortfolios" type="button">Upload</b-button>
      </div>
    </b-modal>
    <b-modal v-model="updateModalShow" hide-footer centered 
      title="Update Portfolio Image" title-class="font-18">
      <vue-dropzone id="updatePortImageDrop" ref="updatePortImageDrop"
      :use-custom-slot="true" :maxFiles="1">
        <div class="needsclick m-0">
          <i class="h1 text-muted ri-upload-cloud-2-line"></i>
          <h4>Drop file here or click to upload.</h4>
          <span class="text-muted font-13">
            (File should not be more than 2MB)
          </span>
        </div>
      </vue-dropzone>
      <div class="d-flex mt-3 justify-content-end">
        <b-button class="mr-1"  @click.prevent="updateModalShow = false">Close</b-button>
        <b-button variant="primary" @click.prevent="updatePortfolio()" type="button">Update</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>

import CoolLightBox from 'vue-cool-lightbox'
import UploadVideo from "./UploadVideo.vue";
import VueDropzone from '@/components/VueDropzone.vue';

export default {
   components: {
    UploadVideo,
    CoolLightBox,
    VueDropzone
  },
  data() {
    return {
      uploadModalShow: false,
      updateModalShow: false,
      uploadVideoModalShow:false,
      awaitingEdit:null,
      pagination:{
        currentPage: 1,
        perPage: 10,
        startIndex: 0,
        endIndex: 10
      },
      coolLightBoxIndex: null,
    }
  },
  computed: {
    product(){
        return this.$store.state.productList.product;
    },
    portfolios(){
      return this.product.portfolios
    },
    paginatedPortfolios(){
      return this.portfolios.slice(
        this.pagination.startIndex,
        this.pagination.endIndex
      );
    },
    coolLightBoxFiles(){
      return this.portfolios.map(file => {
        if(file.is_video){
          return {
            title: file.image,
            autoplay: true,
            thumb: this.absoluteUrl(file.path),
            src: this.absoluteUrl(file.video_path) }
        }else{
          return {
            title: file.image,
            src: this.absoluteUrl(file.path)}
        }
      })
    }
  },
  methods: {
    showImg(index) {
      this.coolLightBoxIndex = this.portfolios.findIndex((item) => item.id == index)
    },
    onPageChange() {
      this.pagination.startIndex = (this.pagination.currentPage - 1) * this.pagination.perPage;
      this.pagination.endIndex = (this.pagination.currentPage - 1) * this.pagination.perPage + this.pagination.perPage;
    },
    initEditPort(portfolio){
      if(portfolio.is_video){
        this.alertError("Please just delete the enter video + cover image and upload fresh"); return;
      }
      this.awaitingEdit = portfolio.id;
      this.updateModalShow = true
    },
    deleteItem(itemId){
      Swal.fire({
        title: "Are you sure?",
        text: "You are about to delete this image!",
        reverseButtons:true,
        showCancelButton: true,
        confirmButtonColor: "#e11",
        cancelButtonColor: "#111",
        confirmButtonText: "Delete it!",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/portfolios/${itemId}/delete`)
          .then((response) => {
            this.$store.dispatch("changeLoaderValue")
            if(response.data.success){
              this.$store.commit("productList/DELETE_PORTFOLIO", itemId)
            }
          })
        }
      });
    },
    updatePortfolio(){
      var arrafile = this.$refs.updatePortImageDrop.getAcceptedFiles();
      if(!arrafile.length || !this.awaitingEdit){this.alertError("Portfolio Image is Required"); return;}
      let formData = new FormData();
      formData.append('image', arrafile[0])
      this.$store.dispatch("changeLoaderValue", true)
      this.$http.post(`/portfolios/${this.awaitingEdit}/update`, formData)
      .then((response) => {
        this.$store.dispatch("changeLoaderValue")
         if(response.data.success){
          this.$store.commit("productList/UPDATE_PORTFOLIO", response.data.data)
          this.clearAll()
        }
       })
    },
    uploadPortfolios(){
      var arrafile = this.$refs.productUploadImageDrop.getAcceptedFiles();
      if(!arrafile.length){this.alertError("Portfolio Image is Required"); return;}
      let formData = new FormData();
      arrafile.forEach((item, index) => formData.append('image_'+index, item))
      formData.append('product_id', this.product.id)
      this.$store.dispatch("changeLoaderValue", true)
      this.$http.post(`/portfolios/create`, formData)
      .then((response) => {
        this.$store.dispatch("changeLoaderValue")
        if(response.data.success){
          this.$store.commit("productList/SET_PORTFOLIOS", response.data.data)
          this.clearAll()
        }
      })
    },
    clearAll(){
      this.uploadModalShow = false;
      this.updateModalShow = false;
      this.uploadVideoModalShow = false;
      this.awaitingEdit = null;
      if(this.$refs.updatePortImageDrop){this.$refs.updatePortImageDrop.removeAllFiles();}
      if(this.$refs.productUploadImageDrop){this.$refs.productUploadImageDrop.removeAllFiles();}
    }
  },
}
</script>

<style scoped>

@media(max-width: 500px){
  .font-sm.image-name{
    font-size: 10px !important;
  }
}

.video-icon-dp{
  line-height: 1;
  left: 42%;
  top: 30%;
  color: #000;
  background: #fff;
  cursor: pointer;
  transition: all 0.24s ease-in;
}

.video-icon-dp:hover{
  background: #000;
  color: #fff;
}
</style>
