<template>
    <div class="">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group mb-3">
                    <input type="text" v-validate="{ required: true}" v-model="data.name"
                    id="inputName" name="name" class="form-control" :class="{ 'is-invalid': errors.has('name') }"
                    placeholder="Enter name" />
                    <span class="text-danger small"  v-show="errors.has('name')">{{ errors.first('name') }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group mb-3">
                    <input type="text" v-validate="{ required: !is_photo_gallery}" v-model="data.value"
                    id="inputValue" name="value" class="form-control" :class="{ 'is-invalid': errors.has('value') }"
                    placeholder="Value or url or color codes" />
                    <span class="text-danger small"  v-show="errors.has('value')">{{ errors.first('value') }}</span>
                    <div>
                        <small @click="is_photo_gallery = !is_photo_gallery" >
                            <span v-if="!is_photo_gallery" class="text-success cursor-pointer">Click to add file</span>
                            <span v-else class="text-warning cursor-pointer">Click to hide uploader</span>
                            for photo gallery
                        </small>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group mb-3">
                    <input type="text" v-model="data.description"
                    name="description" class="form-control"
                    placeholder="Write description" />
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group mb-3">
                    <b-form-checkbox v-model="data.default" name="default" switch>
                        Default Selection
                    </b-form-checkbox>
                </div>
            </div>
            <div v-if="is_photo_gallery" class="col-lg-12 mb-3">
                <vue-dropzone id="reqValeUploadDrop" ref="reqValeUploadDrop"
                :use-custom-slot="true" :maxFiles="1"
                    acceptedFiles="image/*,.webp">
                    <div class="my-0 needsclick">
                        <i class="h1 text-muted ri-upload-cloud-2-line"></i>
                        <h4 class="my-1"> {{editMode? 'Add to update image (photo gallery)':'Drop file or click to add'}}</h4>
                        <span class="text-muted font-13">
                            (Dimension should be 500 x 500 Max: 23kb)
                        </span>
                    </div>
                </vue-dropzone>
            </div>
            <div class="col-12">
                <div class="d-flex align-items-center justify-content-end">
                    <b-button class="mr-2" variant="primary" 
                    @click.prevent="editMode? update() : create()"
                     type="button">{{editMode? 'Update':'Submit'}}</b-button>
                     <b-button variant="danger" 
                    @click.prevent="$emit('exit')"
                     type="button" >Exit Form</b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueDropzone from '../VueDropzone.vue';
export default {
  components: { VueDropzone },
    props:{
        value:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        },
        requirementId:{
            type: Number,
            default: false
        }
    },
    data(){
        return{
            is_photo_gallery: false,
            data: {
                requirement_id: this.requirementId,
                name: "",
                value: "",
                default: false,
                description: "",
            },
        }
    },
    methods:{
        create() {
            this.$validator.validateAll().then(result => {
                if (!result){this.alertError("Form is not valid"); return;}
                const formData = new FormData()
                if(this.is_photo_gallery){
                    if(!this.collectFiles().length){this.alertError("add image file"); return;}
                    formData.append('image_file', this.collectFiles()[0])
                }
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/requirement-values/create`, formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue")
                    if(response.data.success){
                        this.$emit("created", response.data.data.value)
                        this.resetForm();
                    }
                })
            })
        },
        update(){
            this.$validator.validateAll().then(result => {
                if (!result){this.alertError("Form is not valid"); return;}
                const formData = new FormData()
                 if(this.is_photo_gallery && this.collectFiles().length){
                    formData.append('image_file', this.collectFiles()[0])
                }
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/requirement-values/${this.data.id}/update`,formData)
                    .then((response) => {
                        this.$store.dispatch("changeLoaderValue")
                        if(response.data.success){
                            this.$emit("updated", response.data.data.value)
                            this.resetForm()
                        }
                    })
            
            })
        },
        collectFiles(){
            let arrafile = [];
            if(this.$refs.reqValeUploadDrop){
                arrafile = [...this.$refs.reqValeUploadDrop.getAcceptedFiles()];
            }
            return arrafile
        },
        resetForm(){
            this.$validator.reset()
        }
    },
    mounted(){
        if(this.editMode){
            this.data = {...this.value}
        }
    }
}
</script>